<template>
    <el-scrollbar max-height="100vh">
        <div id="div-max">
            <div id="div-lb">
                <div id="div-top">
                    <div id="div-top-left">
                        <div id="div-name">
                            <el-image :src="require('@/assets/imgs/ic_logo.png')"
                                      style="width: 3vw;height: 3vw"></el-image>
                            <el-text id="tv-name-0">广州超维度科技有限公司</el-text>
                        </div>
                    </div>
                    <div id="div-top-right">
                        <el-text class="mx-menu" @click="onMenuClick(0)">公司简介</el-text>
                        <el-text class="mx-menu" @click="onMenuClick(1)">企业理念</el-text>
                        <el-text class="mx-menu" @click="onMenuClick(2)">联系方式</el-text>
                        <el-dropdown :hide-on-click="false" class="el-dropdown">
                            <el-text class="mx-menu" @click="onMenuClick(0)">公司产品</el-text>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item>
                                        <a class="a-tag" href="https://www.cwdcn.com/iot/product.html" target="_blank">物联网产品</a>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <a class="a-tag" href="https://www.cwdcn.com/office/"
                                           target="_blank">办公助手</a>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="false" @click="onSignatureClick">签字助手</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <el-text class="mx-menu" @click="onMenuClick(3)">版权</el-text>
                    </div>
                </div>
                <el-carousel :interval="10000" arrow="always" height="100vh" :pause-on-hover="false" ref="toggle">
                    <el-carousel-item v-for="item in images" :key="item" height="100vh" id="ec-content">
                        <div id="div-lb-content"
                             :style="{backgroundImage:'url('+require(`../assets/imgs/lunbo_${item.imgIndex}.png`)+')'}">
                            <div style="position: absolute" id="div-des">
                                <el-text style="color: #61D3DD;font-size:2vw;font-family: '楷体',serif;
                                width: 100%;display: flex;justify-content: flex-start">
                                    {{ item.title }}
                                </el-text>
                                <el-text :style="{color:'#ffffff',fontSize:'1vw',marginTop:'10px',width:item.desWidth}">
                                    <label v-if="item.imgIndex!=2">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>&nbsp;{{ item.des }}
                                </el-text>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="div-icp" id="div-icp">
                Copy Right © {{mCpnYear}} &nbsp;广州超维度科技有限公司&nbsp; 版权所有 &nbsp;<a class="a-icp"
                                                                                       href="https://beian.miit.gov.cn/"
                                                                                       target="_blank">粤ICP备2024268113号</a>
            </div>
        </div>
    </el-scrollbar>
  <!--小程序签字助手-->
    <div id="div-login-dialog">
        <el-dialog v-model="signDialogVisible" title="请用微信扫码使用小程序" width="300px" center draggable
                   align-center style="border-radius: 8px;" @close="closeSignWindow">
            <template #default>
                <div style="height: 200px;display: flex;justify-content: center;align-items: center">
                    <qrcode-vue :value="qrCodeVal" size="200"/>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue"
import QrcodeVue from "qrcode.vue";
// 获取assets静态资源
const images = ref([
    {
        imgIndex: "0",
        title: "公司简介",
        desWidth: "30vw",
        des: "广州超维度科技有限公司，位于广东省广州市，是一家以从事物联网设备制造、技术开发，信息技术咨询服务、技术服务、技术咨询、\n" +
            " 技术交流、技术转让、技术推广，计算机系统服务，信息系统集成服务，智能控制系统集成，网络与信息安全软件开发，物联网设备销售，\n" +
            " 计算机软硬件及辅助设备零售，物联网技术服务，物联网技术研发，5G通信技术服务，数字技术服务，网络技术服务，通信设备销售的高科技企业。"
    },
    {
        imgIndex: "1",
        title: "企业理念",
        desWidth: "20vw",
        des: "搞技术，我们是认真的；" +
            "我们尽管努力，口碑您说了算。"
    },
    {
        imgIndex: "2",
        title: "联系方式",
        desWidth: "30vw",
        des: "手机号:13423655508 ， QQ:1005510922"
    }
]);
const signDialogVisible = ref(false);
const qrCodeVal = ref("https://www.cwdcn.com/AmwSignature?type=1");
const toggle = ref(null);
const mCpnYear = ref(null);

function onMenuClick(index) {
    let mdId = "";
    switch (index) {
        case 0:
            mdId = "#div-lb";
            toggle.value.setActiveItem(0);
            break
        case 1:
            mdId = "#div-lb";
            toggle.value.setActiveItem(1);
            break
        case 2:
            mdId = "#div-lb";
            toggle.value.setActiveItem(2);
            break
        case 3:
            mdId = "#div-icp";
            break
    }
    document.querySelector(mdId)
        .scrollIntoView({block: "start", behavior: "smooth"});
}

function onSignatureClick() {
    signDialogVisible.value = true;
}

function closeSignWindow() {
    signDialogVisible.value = false;
}

onMounted(() => {
    console.log("");
    let  mYear=new Date();
    mCpnYear.value="2024~"+mYear.getFullYear();
});

onUnmounted(() => {
    console.log("")
});

</script>

<style scoped>
.el-dropdown, .el-dropdown * {
    outline: none;
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

#div-max {
    width: 100%;
}

#div-lb {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

#ec-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#div-lb-content {
    width: 100%;
    height: 100vh;
    transform: scale(1, 1);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    background-size: cover;
}

#div-des {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 15vw;
}

#div-top {
    width: 100vw;
    height: 50px;
    position: absolute;
    z-index: 100;
    margin-top: 8vh;
    display: flex;
    justify-content: space-between;
}

#div-top-left {
    width: 40%;
}

#div-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15vw;
}

#tv-name-0 {
    color: #ffffff;
    font-size: 1.6vw;
    font-weight: bold;
}

#div-top-right {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10vw;
}

.mx-menu {
    margin-right: 50px;
    color: white;
    font-size: 1vw;
}

.mx-menu:hover {
    transition-duration: 0.3s;
    transition-property: box-shadow;
    box-shadow: 0 0 60px #03fa3c;
    cursor: pointer;
    color: #09B2B5;
}

.a-tag {
    color: #7a7878;
    text-decoration: none;
}

.div-icp {
    width: 100vw;
    height: 3vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 0.8vw;
}

.a-icp {
    color: #000000;
    text-decoration: none;
}

</style>